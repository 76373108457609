import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './carousel.module.css';

export interface ImageProps {
  elements?: JSX.Element[];
  settings?: CarouselSettings;
}

export interface CarouselSettings {
  showArrows?: boolean;
  centerMode?: boolean;
  centerSlidePercentage?: number;
  showThumbs?: boolean;
  showStatus?: boolean;
  showIndicators?: boolean;
  swipeable?: boolean;
  swipeScrollTolerance?: number;
  emulateTouch?: boolean;
  autoPlay?: boolean;
  infiniteLoop?: boolean;
  width?: string;
}

export function ImageCarousel(props: ImageProps) {
  const defaultSettings = {
    showArrows: true,
    centerMode: true,
    centerSlidePercentage: 100,
    showThumbs: false,
    showStatus: false,
    showIndicators: false,
    swipeable: true,
    swipeScrollTolerance: 5,
    emulateTouch: true,
    autoPlay: false,
    infiniteLoop: true,
    width: '100%',
    ...props.settings,
  };

  return (
    <Carousel
      showArrows={defaultSettings.showArrows}
      centerMode={defaultSettings.centerMode}
      centerSlidePercentage={defaultSettings.centerSlidePercentage}
      showThumbs={defaultSettings.showThumbs}
      showStatus={defaultSettings.showStatus}
      showIndicators={defaultSettings.showIndicators}
      swipeable={defaultSettings.swipeable}
      swipeScrollTolerance={defaultSettings.swipeScrollTolerance}
      emulateTouch={defaultSettings.emulateTouch}
      autoPlay={defaultSettings.autoPlay}
      infiniteLoop={defaultSettings.infiniteLoop}
      width={defaultSettings.width}
      className={styles.carousel}
    >
      {props?.elements?.map((el, index) => (
        <div key={index}>
          {/* <Box component="img" src={image.path} sx={{ width: 400 }}></Box> */}
          {el}
        </div>
      ))}
    </Carousel>
  );
}
