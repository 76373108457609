import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem } from '@mui/material';
import loaderImg from '@/assets/product/infuser/loader-bg.png';

import ModuleIcon from '@/assets/icon/module.svg';
import KeyIcon from '@/assets/icon/key.svg';
import GraphIcon from '@/assets/icon/graph.svg';
import DocIcon from '@/assets/icon/doc.svg';
import IconBox from 'src/components/iconbox/IconBox';

const DataInfuserComponentGateWay: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box className="product-section">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Box className="img-bg-box">
            <Box className={`bg-item white word-20 p1-20`}>LOADER(원천 데이터 수집/연계 모듈)</Box>
            <img src={loaderImg} alt="Data Infuser Manager" className="data-infuser-manager" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={6}>
          <Box className="word-20 fw-4">
            Loader는 <strong>Raw Data에서 데이터를 추출하고, 분석하고, 저장하는 서비스</strong>입니다.
            <br />
            <br /> 사용자가 Raw Data(파일 또는 DBMS)를 업로드하면 Designer는 메모리 DB를 통해 Loader를 호출합니다.
            <br />
            <br />
            이후 Loader는 Raw Data에서 데이터를 추출하고 작업대기열을 통해 분석합니다. 또한 데이터의 스키마를 정의하고,
            스키마 테이블을 생성하여 메타데이터 DB에 테이블을 저장합니다.
            <br />
            <br />
            Loader는 사용자가 설정한 로드 주기에 따라 가공된 데이터를 업데이트할 수 있습니다.
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Box className={`bg-item ${isMobile ? 'block-sm' : ''}`}>
            <Grid container spacing={1}>
              <IconBox
                icon={ModuleIcon}
                text={
                  <>
                    데이터 <br /> 수집모듈
                  </>
                }
              />
              <IconBox
                icon={KeyIcon}
                text={
                  <>
                    데이터
                    <br />
                    연계모듈
                  </>
                }
              />
              <IconBox
                icon={GraphIcon}
                text={
                  <>
                    메타데이터
                    <br />
                    추출 모듈
                  </>
                }
              />
              <IconBox
                icon={DocIcon}
                text={
                  <>
                    스키마
                    <br />
                    생성 모듈
                  </>
                }
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataInfuserComponentGateWay;
