import { Box } from '@mui/material';

import qufaStudioDiff from '@/assets/imgs/products/qufa_diff.png';

export default function QufaStudioDifferentiation() {
  return (
    <Box className="pb-100">
      <Box className="qufa-desc-title pt-100">Qufa Studio 특장점</Box>
      <Box className="qufa-desc-sub">데이터 진단에 특출난 쿠파 스튜디오만의 특장점에 대해서 설명드리겠습니다.</Box>
      <Box textAlign={'center'}>
        <Box className="qufa-content-diff" component="img" src={qufaStudioDiff}></Box>
      </Box>
    </Box>
  );
}
