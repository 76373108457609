import React from 'react';
import { Box } from '@mui/material';

interface LoadMoreImageProps {
  onClick: () => void;
  imageSrc: string;
  altText?: string;
  width?: string | number;
  height?: string | number;
}

const LoadMoreImage: React.FC<LoadMoreImageProps> = ({
  onClick,
  imageSrc,
  altText = 'Load More',
  width = '100px',
  height = 'auto',
}) => {
  return (
    <Box textAlign="center" mt={3}>
      <Box
        component="img"
        src={imageSrc}
        alt={altText}
        onClick={onClick}
        sx={{ cursor: 'pointer', width: width, height: height }}
      />
    </Box>
  );
};

export default LoadMoreImage;
