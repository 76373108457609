import ptechImage from '@/assets/promptech_logo.png';
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger,
  Slide,
} from '@mui/material';
import styles from './topnav.module.css';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface NavProps {
  isNavHide?: boolean;
}

interface HideOnScrollProps {
  children: React.ReactElement;
  window?: () => Window;
}

const drawerWidth = 240;
const subNavMenuItems = [
  { title: '주요연혁', url: '/histories' },
  { title: '특허', url: '/patents' },
];

const subNavProductItems = [
  { title: 'Data Infuser', url: '/products/infuser' },
  { title: 'AI Data Verifier', url: '/products/verifier' },
  { title: 'Data Map', url: '/products/datamap' },
  { title: 'Qufa Studio', url: '/products/qufa-studio' },
];

const navItems = [
  { title: '홈', url: '/' },
  { title: '제품소개', url: '', submenu: subNavProductItems },
  { title: '주요연혁', url: '', submenu: subNavMenuItems },
  // { title: '포트폴리오', url: '' },
  { title: '회사소개', url: '/aboutus' },
  // { title: '문의하기', url: '' },
];

const mobileNavItems = [
  { title: '홈', url: '/' },
  { title: '제품소개-Data Infuser', url: '/products/infuser' },
  { title: '제품소개-AI Data Verifier', url: '/products/verifier' },
  { title: '제품소개-Data Map', url: '/products/datamap' },
  { title: '제품소개-Qufa Studio', url: '/products/qufa-studio' },
  { title: '주요연혁', url: '/histories' },
  { title: '특허', url: '/patents' },
  { title: '회사소개', url: '/aboutus' },
];

function HideOnScroll(props: HideOnScrollProps) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function TopNav(props: NavProps) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMouseEnter = (index: number) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box sx={{ my: 2 }}>
        <Link href="/">
          <img src={ptechImage} height={30} />
        </Link>
      </Box>
      <Divider />
      <List>
        {mobileNavItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link href={item.url} key={index} className={styles['submenu-item']}>
                <ListItemText primary={item.title} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <HideOnScroll {...props}>
        <AppBar component="nav" className={`${styles['nav-header']}`}>
          <Container maxWidth="xl">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <FontAwesomeIcon icon={faBars} />
              </IconButton>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                <Link href="/">
                  <img src={ptechImage} height={30} alt="Promptech Logo" />
                </Link>
              </Box>

              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItems.map((item, index) => (
                  <Box
                    key={index}
                    sx={{ display: 'inline-block', position: 'relative' }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Button component={Link} href={item.url} className={`${styles['nav-link']}`}>
                      {item.title}
                    </Button>
                    {item.submenu && hoverIndex === index && (
                      <Box className={styles['submenu']}>
                        {item.submenu.map((subItem, subIndex) => (
                          <Link href={subItem.url} key={subIndex} className={styles['submenu-item']}>
                            {subItem.title}
                          </Link>
                        ))}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
