import { Box, Container, Grid } from '@mui/material';

interface TopBannerProps {
  bgImg: string;
  typeImg?: string;
  title?: string;
  desc?: string;
}

export default function TopBanner(props: TopBannerProps) {
  return (
    <Box
      className="top-banner"
      sx={{
        backgroundImage: `url(${props.bgImg})`,
      }}
    >
      <img className="banner-typo" src={props.typeImg} />
      <Container maxWidth="xl">
        <Grid item lg={12} className="text-right">
          <Box className="product-intro">
            <Box className="product-intro title">{props.title}</Box>
            <Box className="product-intro desc">{props.desc}</Box>
            {/* <h4>{props.desc}</h4> */}
          </Box>
        </Grid>
      </Container>
    </Box>
  );
}
