import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem } from '@mui/material';

import loaderImg from '@/assets/product/infuser/manager-bg.png';

import ModuleIcon from '@/assets/icon/module.svg';
import KeyIcon from '@/assets/icon/key.svg';
import GraphIcon from '@/assets/icon/graph.svg';
import DocIcon from '@/assets/icon/doc.svg';
import IconBox from 'src/components/iconbox/IconBox';

const DataInfuserComponentManager: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box className="product-section">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Box className="img-bg-box">
            <Box className={`bg-item white word-26 p1-20`}>MANAGER(서비스 관리 시스템)</Box>
            <img src={loaderImg} alt="Data Infuser Manager" className="data-infuser-manager" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={6}>
          <Box className="word-20 fw-4">
            Manager는{' '}
            <strong>시스템 관리자가 생성되거나 배포된 API의 상태를 확인할 수 있는 GUI 기반 관리 서비스</strong>입니다.
            <br />
            <br />
            시스템 관리자는 Manager를 통해 배포된 API의 상태와 로그를 관리할 수 있습니다. Manager는 다차원 분석 및 통계
            쿼리를 통해 통계 데이터도 제공합니다.
            <br />
            <br />
            이를 통해 관리자는 API 상태, 트래픽 변화, API별 트래픽 수 등을 시각적으로 확인할 수 있습니다.
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Box className={`bg-item ${isMobile ? 'block-sm' : ''}`}>
            <Grid container spacing={1}>
              <IconBox
                icon={ModuleIcon}
                text={
                  <>
                    이용자
                    <br />
                    관리 모듈
                  </>
                }
              />
              <IconBox
                icon={KeyIcon}
                text={
                  <>
                    로그
                    <br />
                    조회 모듈
                  </>
                }
              />
              <IconBox
                icon={GraphIcon}
                text={
                  <>
                    다차원
                    <br />
                    분석 모듈
                  </>
                }
              />
              <IconBox
                icon={DocIcon}
                text={
                  <>
                    시각화 모듈
                    <br />
                  </>
                }
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataInfuserComponentManager;
