import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography } from '@mui/material';

import BannerImage from '@/assets/imgs/companies/company_banner.png';
import CompanyHeaderText from '@/assets/imgs/companies/company-header-text.svg';

import TopBanner from '@/components/product/TopBanner';
import OrganizationMain from '@/views/company/organization/OrganizationMain';
import OrganizationCharacteristic from '@/views/company/organization/OrganizationCharacteristic';
import OrganizationChart from '@/views/company/organization/OrganizationChart';
import OrganizationSummary from '@/views/company/organization/OrganizationSummary';
import OrganizationBusiness from '@/views/company/organization/OrganizationBusiness';

const Company: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <TopBanner bgImg={BannerImage} typeImg={CompanyHeaderText} title="회사소개" desc="기업 개요 및 조직도" />
      <Container maxWidth="xl">
        <OrganizationMain />
        <OrganizationBusiness />
        <OrganizationChart />
        <OrganizationCharacteristic />
        <OrganizationSummary />
      </Container>
    </Box>
  );
};

export default Company;
