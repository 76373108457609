import DefaultLayout from '@/layouts/DefaultLayout';
import HomeLayout from '@/layouts/HomeLayout';
import Company from '@/views/company/Company';
import HomeIndex from '@/views/home';
import { TemplateIndex } from '@/views/template';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import History from '../views/history/History';
import Patent from '../views/patent/Patent';
import DataVerifier from '../views/product/DataVerifier';
import DataInfuser from '../views/product/DataInfuser';
import QufaStudio from '@/views/product/QufaStudio';
import DataMapMain from '@/views/product/datamap/DataMapMain';

const Routes = () => {
  // Routes
  const routes = [
    {
      element: <DefaultLayout />,
      children: [
        {
          path: '/patents',
          element: <Patent />,
        },
        {
          path: '/histories',
          element: <History />,
        },
        {
          path: '/products/infuser',
          element: <DataInfuser />,
        },
        {
          path: '/products/verifier',
          element: <DataVerifier />,
        },
        {
          path: '/products/datamap',
          element: <DataMapMain />,
        },
        {
          path: '/aboutus',
          element: <Company />,
        },
        {
          path: '/products/qufa-studio',
          element: <QufaStudio />,
        },
      ],
    },
  ];

  const homeRoutes = [
    {
      element: <HomeLayout />,
      children: [
        {
          path: '/',
          element: <HomeIndex />,
        },
        {
          path: '/template',
          element: <TemplateIndex />,
        },
      ],
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([...routes, ...homeRoutes]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
