import React, { useState } from 'react';
import { Container, Grid, Box, Typography, Divider } from '@mui/material';
import { plainPatents } from '../../components/data/PlainPatents';
import LoadMoreButton from '../../components/button/LoadMoreButton';
import loadMoreImage from '@/assets/button/view-more.png';

const PlainPatent: React.FC = () => {
  const [visibleCount, setVisibleCount] = useState(8);

  const loadMorePatents = () => {
    setVisibleCount((prevCount) => prevCount + 8);
  };

  return (
    <Container className="patent-plain-container">
      <Grid container spacing={3}>
        {plainPatents.slice(0, visibleCount).map((patent, idx) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={idx}>
            <Box className="patent-box">
              <Box className="title-box">
                <Typography className='title-text'>{patent.title}</Typography>
              </Box>
              <Divider className="patent-divider" sx={{ borderColor: '#17B2C1 !important' }} />
              <Box className="content-box">
                <Box className="content-item">
                  등록번호 : <strong>{patent.no}</strong>
                </Box>
                <Box className="content-item">
                  저작자 : <strong>{patent.author}</strong>
                </Box>
                <Box className="content-item">
                  등록일 : <strong>{patent.registeredAt}</strong>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      {visibleCount < plainPatents.length && (
        <LoadMoreButton
          onClick={loadMorePatents}
          imageSrc={loadMoreImage}
          altText="Load More"
          width="100px"
          height="auto"
        />
      )}
    </Container>
  );
};

export default PlainPatent;
