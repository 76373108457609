import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem } from '@mui/material';
import dataInfuserManager from '@/assets/product/data-finsuer-designer.png';
import startDoubleQuotation from '@/assets/quotation/start-double-quotation.svg';
import endDoubleQuotation from '@/assets/quotation/end-double-quotation.svg';
import dataInfuserdesc from '@/assets/product/data-infuser-desc2.png';

const ProductInfuserDifferentiation: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Grid item xs={12}>
        <Box className="product-infuser-desc-header-box">
          <Typography className="word-47 main-color">
            <img src={startDoubleQuotation} className="start-double-quotation" />
            데이터 인퓨저는 다양한사업에서
            <img src={endDoubleQuotation} className="end-double-quotation" />
          </Typography>
          <Typography className="word-47 fw-bold main-red-title d-inline">API 구축에 활용</Typography>
          <Typography className="word-47 d-inline">되고 있습니다.</Typography>
        </Box>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <div className="img-bg-box">
            <Box className={`bg-item ${isMobile ? 'block-sm' : ''}`}></Box>
            <img src={dataInfuserManager} alt="Data Infuser Manager" className="data-infuser-manager" />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="description-box">
            <Typography className="word-47 main-color">Data Infuser의 차별성</Typography>
            <Typography className="ul-desc">
              <List
                sx={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside',
                }}
              >
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    Open API 제공 항목 정의, 스키마 정의, 파라미터 설정 등과
                  </Typography>
                  <Typography variant="inherit" display="inline" className="bold-word-20">
                    동시에 기술문서 자동 생성
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    Open API
                  </Typography>
                  <Typography variant="inherit" display="inline" className="bold-word-20">
                    세부사항 변경 시 기술문서 자동 변경
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    으로 현행화 유지
                  </Typography>
                </ListItem>

                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    별도의 문서 편집기 없이 기술문서 참조가 가능한
                  </Typography>
                  <Typography variant="inherit" display="inline" className="bold-word-20">
                    개발자친화적인 환경
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="inherit" display="inline" className="bold-word-20">
                    비개발자도 쉽게 운영 및 관리
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    가 가능한
                  </Typography>
                  <Typography variant="inherit" display="inline" className="bold-word-20">
                    사용자 친화적인 UI/UX
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>국제표준(OAS v3.0) 준용</ListItem>
              </List>
            </Typography>
            <img src={dataInfuserdesc} className="data-infuser-desc2" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductInfuserDifferentiation;
