import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import qufaAi from '@/assets/imgs/products/qufa_ai.png';

export default function QufaStudioMain() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Grid container className="product-section qufa-desc-grid" sx={{ flexGrow: 1 }} spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="qufa-desc-box">
            <Box className="qufa-desc-title">QuFa Studio IA</Box>
            <Box className="qufa-desc-row">
              <Typography className="qufa-desc-sub">뛰어난 쿠파 스튜디오의 기술력을 </Typography>
              <Typography className="qufa-desc-sub bold-word">아키텍쳐</Typography>
              <Typography className="qufa-desc-sub">를 통해 보여드립니다.</Typography>
            </Box>
            <Box className="qufa-desc-row">
              <Typography className="qufa-desc-sub bold-word">데이터 품질 진단에 유용</Typography>
              <Typography className="qufa-desc-sub">하다는 특장점이 있습니다.</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className="qufa-content-title">QuFa Studio IA</Box>
      <Box textAlign={'center'}>
        <Box className="qufa-content" component={'img'} src={qufaAi}></Box>
      </Box>
    </>
  );
}
