import React from 'react';
import Certificate from './Certificate';
import PlainPatent from './PlainPatent';
import { Container } from '@mui/material';
const Patent: React.FC = () => {
  return (
    <Container className="patent-parent-container pb-100" maxWidth="xl">
      <Certificate />

      <PlainPatent />
    </Container>
  );
};

export default Patent;
