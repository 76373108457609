import { Box, Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import HomeSubNavigator from './HomeSubNavigator';

import qufaBgImg from '@/assets/imgs/home/qufa_slide_bg.png';
import qufaStructureImg from '@/assets/imgs/home/qufa_structure.png';
import qufaExampleImg from '@/assets/imgs/home/qufa_ex.png';

import styles from './home.module.css';

import isMobile from '../../components/mobile/useInMobile';

export default function QufaStudio() {
  return (
    <Box
      component={'div'}
      className={'home-item'}
      sx={{
        backgroundImage: `url(${qufaBgImg})`,
        backgroundSize: 'cover',
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          className={`${styles['qufa-studio-title']}`}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid item order={{ xs: 2, md: 1 }}>
            <Box>데이터 품질 및 공정성 관리 통합 솔루션</Box>
          </Grid>
          <Grid item order={{ xs: 1, md: 2 }} sx={{ paddingLeft: { lg: 1 } }}>
            <Box component="span" className={`${styles['qufa-studio-title-gradient']} ${styles.bold}`}>
              QuFa Studio
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} lg={7} className="qufa-arch-wrap">
            <Box
              component="img"
              src={qufaStructureImg}
              className="qufa-arch-img"
              sx={{
                width: { xs: '80%', lg: '100%' },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={5} className="qufa-ex-wrap mobile-d-none-767">
            <Box component="img" src={qufaExampleImg} className="qufa-ex-img" />
          </Grid>
          <Grid item xs={12} md={4} className="qufa-desc-wrap t-18">
            <div className="m-desc-border">
              <Box className="v-line" />
              <div>데이터 프로파일링</div>
              <div>표준 용어 검증</div>
              <div>이상치 감지 및 보정 특징 추출</div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="qufa-desc-wrap t-18">
            <div className="m-desc-border">
              <Box className="v-line" />
              <div>데이터 공정성 분석</div>
              <div>AI 모델 기반의 데이터 공정화</div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="qufa-desc-wrap t-18">
            <div className="m-desc-border">
              <Box className="v-line" />
              <div>단계별 따른 시각화 결과 제공</div>
              <div>GUI 환경의 간편한 기능 제공</div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
