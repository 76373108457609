import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem } from '@mui/material';
import infuserGatewayImg from '@/assets/product/infuser/designer-bg.png';
import GearIcon from '@/assets/icon/gear.svg';
import RestApiIcon from '@/assets/icon/rest-api.svg';
import ApiDefinitionIcon from '@/assets/icon/api-definition.svg';
import FilterIcon from '@/assets/icon/filter.svg';
import IconBox from 'src/components/iconbox/IconBox';

const DataInfuserComponentDesigner: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Grid container spacing={3} direction={isMobile ? 'column-reverse' : 'row'}>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Box className={`bg-item ${isMobile ? 'block-sm' : ''}`}>
            <Grid container spacing={1}>
              <IconBox
                icon={GearIcon}
                text={
                  <>
                    원천데이터 <br /> 소스관리모듈
                  </>
                }
              />
              <IconBox
                icon={RestApiIcon}
                text={
                  <>
                    API생성모듈
                    <br />
                  </>
                }
              />
              <IconBox
                icon={ApiDefinitionIcon}
                text={
                  <>
                    api정의모듈
                    <br />
                  </>
                }
              />
              <IconBox
                icon={FilterIcon}
                text={
                  <>
                    OAS 3.0 <br />
                    명세생성모듈
                  </>
                }
              />
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={6}>
          <Box className="word-20 fw-4">
            <strong>Designer는 Raw Data로부터 API를 생성 및 관리하는 서비스</strong>입니다.
            <br />
            <br />
            Raw Data는 구조화된 파일데이터(CSV, XLSX 등) 또는 DBMS(RDBMS, NoSQL 등)가 될 수 있습니다.
            <br />
            <br />
            사용자는 사용자 인터페이스 내에서 제공 항목, API 명세, 사용자 매개변수, 메소드 등의 API 설정을 정의할 수
            있습니다. 사용자가 API 명세를 요청하면 Designer 메타데이터 DB에 저장된 메타데이터로부터 OAS 3.0 기반의 API
            명세를 구성합니다.
            <br />
            <br />
            사용자는 Loader가 API를 자동으로 업데이트 하도록 로드 주기를 설정할 수 있으며 모든 단계가 완료되면
            최종적으로 API를 배포할 수 있게 됩니다.
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Box className="img-bg-box">
            <Box className={`bg-item white word-20 p1-20`}>DESIGNER(GUI기반의 API 관리도구)</Box>
            <img src={infuserGatewayImg} alt="Data Infuser Manager" className="data-infuser-manager" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataInfuserComponentDesigner;
