import carouselBg01 from '@/assets/main/bg-carousel01.png';
import carouselBg02 from '@/assets/main/bg-carousel02.png';
import carouselBg03 from '@/assets/main/bg-carousel03.png';
import carouselBg04 from '@/assets/main/bg-carousel04.png';
import SlickCarousel, { SlickProps } from '@/components/carousel/SlickCarousel';
import { Box, Container, Stack, Typography } from '@mui/material';
import styles from './home.module.css';

import FeatureItem from './FeatureItem';
import useIsMobile from '@/components/mobile/useInMobile';

export default function Features() {
  const slickProps: SlickProps = {
    infinite: false,
    centerMode: false,
  };

  const isMobile = useIsMobile();
  if (isMobile === true) {
    slickProps.slidesToScroll = 1;
  }

  const BackgroundStack = () => {
    return (
      <Stack className={`${styles['drag-disable']} ${styles['solution-background']}`}>
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{
              fontFamily: 'Roboto',
              opacity: 0.2,
              lineHeight: 1,
            }}
            fontSize={{ xs: 50, sm: 90, md: 120, lg: 150 }}
            fontWeight={900}
            color={'#5F6067'}
          >
            TECHNOLOGY
          </Typography>
        </Box>

        <Box flexGrow={1} />

        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Typography
            alignSelf={'flex-end'}
            sx={{
              textAlign: 'end',
              fontFamily: 'Roboto',
              opacity: 0.2,
              lineHeight: 0.6,
            }}
            fontSize={{ xs: 50, sm: 90, md: 120, lg: 150 }}
            fontWeight={900}
            color={'#5F6067'}
          >
            SOLUTION
          </Typography>
        </Box>
      </Stack>
    );
  };
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <BackgroundStack />
      <Container
        maxWidth="xl"
        sx={{
          height: '100%',
        }}
      >
        <Box component="div" className={`${styles['solution-carousel-wrapper']}`}>
          <SlickCarousel
            settings={slickProps}
            elements={[
              <FeatureItem
                title={'System Development'}
                content={
                  '빅데이터 플랫폼 구축, 공공 데이터 개방체계 구축·데이터 맵, 인공지능 딥러닝 객체인식, 블록체인 기반 시스템'
                }
                bgImgUrl={carouselBg01}
              ></FeatureItem>,
              <FeatureItem
                title={'Products & Services'}
                content={
                  '오픈API 자동화 시스템 Data Infuser\n 통합 API 관리 시스템 API Gateway\n 품질 및 공정성 통합 솔루션 QuFa Studio'
                }
                bgImgUrl={carouselBg02}
              ></FeatureItem>,
              <FeatureItem
                title={'Maintenance & DB Consulting'}
                content={'웹서비스 운영 유지보수 대행, 데이터베이스 위탁 관리 대행, 데이터베이스 설계, 튜닝, 이관'}
                bgImgUrl={carouselBg03}
              ></FeatureItem>,
              <FeatureItem
                title={'Design Performance'}
                content={
                  '웹디자인, 기업 CI, BI 제작, 홈페이지 디자인, 제작, 운영 유지보수, 기업 제품 브로셔 및 리플렛 제작, 제품 홍보 모션 그래픽 제작'
                }
                bgImgUrl={carouselBg04}
              ></FeatureItem>,
            ]}
          />
        </Box>
        <Box component="div" className={`${styles['solution-wrapper']}`}>
          <Box
            sx={{
              alignSelf: 'start',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40px"
              height="30px"
              viewBox="0 0 40 30"
              version="1.1"
              className={`${styles['solution-svg']}`}
            >
              <path
                d="M0,30 L0,12.7208481 L7.32934132,0 L15.9520958,0 L9.48502994,12.7208481 L18,12.7208481 L18,30 L0,30 Z M22,30 L22,12.7208481 L29.3293413,0 L37.9520958,0 L31.4850299,12.7208481 L40,12.7208481 L40,30 L22,30 Z"
                fill="currentColor"
              ></path>
            </svg>
          </Box>
          <Box className={`${styles['desc']}`}>
            기술의 가치를 끌어올릴 수 있는
            <br />
            프람트 테크놀로지의{' '}
            <Typography component={'span'} className={`${styles['desc-bold']}`}>
              솔루션
            </Typography>
            을 소개합니다
          </Box>
          <Box
            sx={{
              alignSelf: 'start',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40px"
              height="30px"
              viewBox="0 0 40 30"
              version="1.1"
              className={`${styles['solution-svg']}`}
              transform="rotate(180)"
            >
              <path
                d="M0,30 L0,12.7208481 L7.32934132,0 L15.9520958,0 L9.48502994,12.7208481 L18,12.7208481 L18,30 L0,30 Z M22,30 L22,12.7208481 L29.3293413,0 L37.9520958,0 L31.4850299,12.7208481 L40,12.7208481 L40,30 L22,30 Z"
                fill="currentColor"
              ></path>
            </svg>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
