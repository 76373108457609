export const plainPatents = [
  {
    title: '안전한 분산 집합정보물 결합 방법',
    no: '제10-2382314호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2022-03-30',
  },
  { title: '데이터셋 관리 장치 및 방법', no: '제10-2100346호', author: '전종훈', registeredAt: '2020-04-08' },
  {
    title: '데이터셋 관리 장치 및 방법',
    no: '제10-2019-0106619호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2019-08-30',
  },
  {
    title: '삼분 그래프에 기반한 추천 방법',
    no: '제 10-1985603 호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2019-05-28',
  },
  {
    title: '비식별화가 수행된 비정형 텍스트에 대한 속성 기반 유용도 측정 방법',
    no: '제 10-2019-0014900 호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2019-02-08',
  },
  {
    title: '컨텐츠 융합형 동적 광고 시스템',
    no: ' 제 10-1945726 호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2019-01-30',
  },
  {
    title: '분산 집합정보물 결합 방법',
    no: '제 10-2018-0169404 호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2018-12-26',
  },
  {
    title: 'AILMS(인공지능 라이프사이클 관리 시스템)',
    no: '제 C-2018-018787 호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2018-07-24',
  },
  {
    title: '삼분 그래프에 기반한 추천 방법',
    no: '제10-2017-0159381호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2017-11-27',
  },
  {
    title: '컨텐츠 융합형 동적 광고 시스템',
    no: '제10-2017-0035369호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2017-03-21',
  },
  {
    title: '개인 맞춤형 광고 미디어 콘텐츠 제공 방법',
    no: '10-2017-0002751',
    author: '(주)프람트테크놀로지',
    registeredAt: '2017-01-09',
  },
  {
    title: '사용자 리뷰를 이용한 상품 평가 시스템 및 방법',
    no: '10-2017-0001275',
    author: '(주)프람트테크놀로지',
    registeredAt: '2017-01-04',
  },
  {
    title: '피봇언어를 사용한 음식점 메뉴 번역 방법 특허등록',
    no: '제10-1683801호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2016-12-01',
  },
  {
    title: '이기종 데이터 통합을 위한 미디에이터 시스템',
    no: '제10-2016-0063789호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2016-05-25',
  },
  {
    title: '이기종 데이터통합을 위한 미디에이터 시스템',
    no: '제 C-2016-012503 호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2016-05-25',
  },
  {
    title: '다국어기반 메뉴정보 관리 시스템',
    no: '제10-2016-006878호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2016-03-18',
  },
  {
    title: '피봇언어를 사용한 음식점 메뉴 번역 방법',
    no: '제 10-2016-0007175 호',
    author: '전종훈',
    registeredAt: '2016-01-10',
  },
  {
    title: '사용자의 이용 패턴에 따른 개인화 서비스',
    no: '제C-2015-014979호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2015-06-26',
  },
  { title: '키오스크 디자인등록', no: '제2014-0007297호', author: '(주)프람트테크놀로지', registeredAt: ' 2015-04-27' },
  {
    title: '개인화 서비스를 위한 데이터웨어하우스 로깅 시스템',
    no: '제C-2015-004635호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2015-03-02',
  },
  {
    title: '폴라리티 기반 사용자 의견 랭킹 산정방법 및 시스템',
    no: '제 10-2015-0008803 호',
    author: '전종훈, 임종현',
    registeredAt: '2015-01-19',
  },
  {
    title: '개선된 하이퍼 큐브 인덱스 구축방법을 포함한 하이퍼 큐브를 이용한 추론 검색 방법',
    no: '제10-1441035호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2014-09-05',
  },
  {
    title: '엠씨보드 클라이언트 안드로이드 앱',
    no: '제C-2014-010126',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2014-05-02',
  },
  {
    title: '멀티 타임존을 고려한 지능형 예약 모듈',
    no: '제C-2014-008894',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2014-04-17',
  },
  {
    title: 'mcBoard client App for Android 소프트웨어 등록',
    no: '제C-2014-004119호',
    author: '(주)프람트테크놀로지, 한영근',
    registeredAt: ' 2014-02-20',
  },
  {
    title: '디지털정보표시 장치 (디지털 메뉴보드 키오스크) 디자인 등록 출원',
    no: '30-2014-0007297',
    author: '(주)프람트테크놀로지, 한영근',
    registeredAt: '2014-02-13',
  },
  {
    title: '무선통신을 이용한 신호등 정보 제공 시스템',
    no: ' 제10-1352971호',
    author: '전종훈',
    registeredAt: ' 2014-01-13',
  },
  {
    title: '하이퍼 큐브를 사용한 추론 검색 방법',
    no: ' 제10-1318250호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2013-10-08',
  },
  {
    title: 'Menucloud 서비스표 등록',
    no: '제41-0267331호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2013-09-04',
  },
  {
    title: 'Menucloud 서비스표 등록',
    no: '제41-0267332호',
    author: '(주)프람트테크놀로지',
    registeredAt: ' 2013-09-04',
  },
  {
    title: '개선된 하이퍼 큐브 인덱스 구축방법을 포함한 하이퍼 큐브를 이용한 추론 검색 방법',
    no: '제 2012-0104439 호',
    author: '(주)프람트테크놀로지',
    registeredAt: '2012-09-20',
  },
  { title: 'Cloudmenu 상표출원', no: '41-2012-00002652', author: '(주)프람트테크놀로지', registeredAt: '2012-08-01' },
  {
    title: '디지털 메뉴 통합관리 시스템 및 그 운영방법',
    no: '10-2012-0054056',
    author: '권동섭, 전종훈',
    registeredAt: '2012-05-22',
  },
  {
    title: '하이퍼 큐브를 사용한 추론 검색 방법',
    no: '제 2012-0013861 호',
    author: '전종훈, 김인성',
    registeredAt: '2012-02-10',
  },
  {
    title: '위치기반 스타정보 공유 시스템 및 방법',
    no: '10-2012-0012390',
    author: '전종훈, 권동섭',
    registeredAt: '2012-02-07',
  },
  {
    title: '무선통신을 이용한 신호등 정보 제공 시스템',
    no: '제 2011-0092474 호',
    author: '전종훈, 권동섭, 김상균',
    registeredAt: '2011-09-14',
  },
];
