import { CarouselSettings } from '@/components/carousel/ImageCarousel';
import { Footer } from '@/layouts/Footer';
import { Box, Typography } from '@mui/material';
import { FullPage, Slide } from 'react-full-page';
import MainSlide from './MainSlide';
import Features from './Features';
import DataInfuser from './DataInfuser';
import DocInfuser from './DocInfuser';
import ControlButton, { ButtonProp } from '@/components/fullpage/ControlButton';
import Datamap from './DataMap';
import QufaStudio from './QufaStudio';
import DataVerifier from './DataVerifier';
import ScrollHelper from './ScrollHelper';
import { useState } from 'react';

export default function HomeIndex() {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const controlButtonProps: ButtonProp = {
    pageNumber: 3,
    title: 'data infuser',
    onSlideChange: setCurrentSlideIndex,
  };

  return (
    <FullPage
      controls={(props) => <ControlButton {...props} controlButtonProps={controlButtonProps} />}
      duration={1200}
      initialSlide={0}
    >
      <Slide>
        <MainSlide />
      </Slide>
      <Slide className="mobile-d-none-600">
        <Features />
      </Slide>
      <Slide>
        <DataInfuser />
      </Slide>
      <Slide>
        <DataVerifier />
      </Slide>
      <Slide>
        <DocInfuser />
      </Slide>
      <Slide>
        <Datamap />
      </Slide>
      <Slide>
        <QufaStudio />
      </Slide>
      <Slide style={{ maxHeight: '163.5px' }}>
        <Footer></Footer>
      </Slide>

      <ScrollHelper currentIndex={currentSlideIndex} />
    </FullPage>
  );
}
