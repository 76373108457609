import React from 'react';
import { Box, Container, Grid, styled, SvgIcon, Typography, createTheme, responsiveFontSizes } from '@mui/material';

import HeaderBg from '@/assets/product/datamap/data-map-header.png';
import DataMapHeaderText from '@/assets/product/datamap/data-map-header-text.svg';
import ContentImage from '@/assets/product/datamap/data-map-content-2.png';
import Step1Image from '@/assets/product/datamap/data-map-step-1.png';
import Step2Image from '@/assets/product/datamap/data-map-step-2.png';
import Step3Image from '@/assets/product/datamap/data-map-step-3.png';
import { ReactComponent as StartDoubleQuote } from '@/assets/quotation/start-double-quotation.svg';
import { ReactComponent as EndDoubleQuote } from '@/assets/quotation/end-double-quotation.svg';
import DataMapIA from '@/assets/product/datamap/data-map-ia.png';
import DataMapRighDirection from '@/assets/product/datamap/data-map-right-direction.png';
import DataMapUses from '@/assets/product/datamap/data-map-uses.png';
import StepCard from './StepCard';
import TopBanner from '@/components/product/TopBanner';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const DataMapMain: React.FC = () => {
  const ProductContainer = styled(Container)({
    padding: '100px',
  });

  const HeaderBox = styled(Box)({
    height: 480,
    backgroundImage: `url(${HeaderBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
  });

  const TitleBox = styled(Box)({
    color: '#26272F',
    marginBottom: 100,
  });

  const Title = styled(Typography)({
    fontSize: '44px',
    fontWeight: 700,
  });

  const SubTitle = styled(Typography)({
    fontSize: '20px',
    fontWeight: 400,
  });

  const Emphasis = styled(Box)({
    color: '#BB313D',
    fontSize: '34px',
    fontWeight: 700,
  });

  return (
    <>
      <TopBanner bgImg={HeaderBg} typeImg={DataMapHeaderText} title="Data Map" desc="데이터맵" />
      <ProductContainer maxWidth="xl" className="data-map-container">
        <Grid container alignItems="center" marginBottom={'200px'}>
          <Grid item lg={12}>
            <TitleBox>
              <Title>데이터맵</Title>
              <Typography variant="h2"></Typography>
              <SubTitle sx={{ marginTop: '44px' }}>
                당사는 <Emphasis component="span">빅데이터 검색 및 시각화, 거래 기반 마련</Emphasis>을 위한
              </SubTitle>
              <SubTitle>
                <Emphasis component="span">Data Map</Emphasis> 구축기술을 보유하고 있습니다.
              </SubTitle>
            </TitleBox>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box component="img" src={ContentImage} sx={{ width: '100%' }} />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={1} justifyContent="center" alignItems="center" marginBottom={'200px'}>
          <Grid item md={12} lg={12}>
            <TitleBox>
              <Title>Data map의 구성요소 및 단계</Title>
              <SubTitle sx={{ marginTop: '22px', color: '#000' }}>
                데이터맵의 형태는 철저한 단계를 통해 이루어져 있습니다.
              </SubTitle>
            </TitleBox>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <StepCard
              index={1}
              title="메타데이터 어휘사전"
              bg={Step1Image}
              text={'메타데이터의 표준용어간의<br/>연관망 DB 구성'}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <StepCard index={2} title="데이터셋" bg={Step2Image} text={'데이터셋간 연관도 정보<br/>DB 구성'} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <StepCard index={3} title="데이터맵" bg={Step3Image} text={'사용자 UI 및 UX 편의성, 조망성<br/>극대화'} />
          </Grid>

          <Grid item lg={12} sx={{ marginTop: '108px' }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box sx={{ alignSelf: 'start' }} className="data-map-double-quote start">
                <StartDoubleQuote />
              </Box>
              <Box sx={{ fontSize: '47px', fontWeight: 600, textAlign: 'center' }}>
                <Emphasis sx={{ fontSize: '48px' }}>연계된 모든 메타데이터를 손쉽게 확인</Emphasis>
                가능하다는 것이 가장 특장점 입니다.
              </Box>
              <Box sx={{ alignSelf: 'start' }} className="data-map-double-quote end">
                <EndDoubleQuote />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={{
            lg: 1,
            md: 1,
            sm: 0,
            xs: 0,
          }}
          minHeight={'850px'}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={12}>
            <TitleBox>
              <Title>Data map 엔진 아키텍처</Title>
              <SubTitle sx={{ marginTop: '22px', color: '#000' }}>
                데이터맵 엔진 아키텍처를 통해 손쉽게 데이터맵을 활용할 수 있습니다.
              </SubTitle>
            </TitleBox>
          </Grid>

          <Grid item md={12} lg={6}>
            <Box component="img" src={DataMapIA} sx={{ width: '100%' }} />
          </Grid>
          <Grid item md={1} lg={1} className="mobile-d-none">
            <Box component="img" src={DataMapRighDirection} sx={{ width: '100%' }} />
          </Grid>
          <Grid item md={12} lg={5}>
            <Box component="img" src={DataMapUses} sx={{ width: '100%' }} />
          </Grid>
        </Grid>
      </ProductContainer>
    </>
  );
};

export default DataMapMain;
