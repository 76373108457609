import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography } from '@mui/material';

import AiDataVerifierImage from '@/assets/imgs/products/ai_data_verifier_bg.png';
const DataVerifier: React.FC = () => {
  return (
    <Box>
      <Container className="" maxWidth="xl">
        <Box className="product-content-box">
          <Grid container className="infuser-descgrid" spacing={3}>
            <Grid item xs={12} sm={8} md={7} lg={7}>
              <Box className="infuser-desc-box">
                <Box className="infuser-desc-title">AI Data Verifier란?</Box>
                <Box className="infuser-desc-row">
                  <Typography className="infuser-desc-sub">첨단&nbsp;</Typography>
                  <Typography className="infuser-desc-sub bold-word">Big Data 기술</Typography>
                  <Typography className="infuser-desc-sub">과&nbsp;</Typography>
                  <Typography className="infuser-desc-sub bold-word">AI 알고리즘</Typography>
                  <Typography className="infuser-desc-sub">을&nbsp;</Typography>
                  <Typography className="infuser-desc-sub bold-word">결합</Typography>
                  <Typography className="infuser-desc-sub">
                    하여
                    <br />
                  </Typography>
                  <Typography className="infuser-desc-sub bold-word">데이터의 정밀한 검증</Typography>
                  <Typography className="infuser-desc-sub">과&nbsp;</Typography>
                  <Typography className="infuser-desc-sub bold-word">실시간 분석을 제공</Typography>
                  <Typography className="infuser-desc-sub">
                    하는
                    <br />
                  </Typography>
                  <Typography className="infuser-desc-sub bold-word">고도화된 데이터 검증 솔루션</Typography>
                  <Typography className="infuser-desc-sub">입니다.</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box className="infuser-desc-box pb-100 pt-100">
              <img src={AiDataVerifierImage} className="big-data-bg-img" />
            </Box>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default DataVerifier;
