export interface History {
  year: string;
  month: string;
  content: string;
  isYearVisible?: boolean;
}

export const histories2024 = [
  {
    year: '2024',
    month: '01',
    content: "2024년 공공데이터포털 및 서비스 위탁운영 ('23~'24)<br/> <strong>(한국지능정보사회진흥원)",
    isYearVisible: true,
  },
];

export const histories2023 = [
  {
    year: '2023',
    month: '11',
    content: '사업용차량 이력정보 개방체계 구축<br/><strong>(국토교통부)</strong>',
    isYearVisible: true,
  },
  {
    year: '2023',
    month: '07',
    content:
      '재난배상책임보험사업장정보개방 및 진위확인 서비스 <br/><strong>(행정안전부)</strong><br/><br/>SRT 지연승차권 데이터 개방<br/><strong>(에스알)</strong>',
  },
  {
    year: '2023',
    month: '06',
    content:
      '열차운행 및 역무편의시설 정보 오픈API 개방 <br/><strong>(한국철도공사)</strong><br/><br/>창업지원 및 교육 데이터 등 개방 <br/><strong>(창업진흥원)</strong><br/><br/>종이형 지역사랑상품권 판매 환전 지점 정보 개방 <br/><strong>(한국조폐공사)',
  },
  {
    year: '2023',
    month: '01',
    content:
      "2023년 공공데이터포털 및 서비스 위탁운영 ('23~'24)<br/><strong>(한국지능정보사회진흥원)</strong><br/><br/>아이돌봄 통합지원 플랫폼 운영 및 유지관리 <br/><strong>(여성가족부)",
  },
];

export const histories2022 = [
  {
    year: '2022',
    month: '08',
    content: '수요자 중심의 공공데이터 개방활용 체계 및 제공 기반 개선 2차 <br/><strong>(한국지능정보사회진흥원)',
    isYearVisible: true,
  },
  {
    year: '2022',
    month: '07',
    content: 'SRT 승차권 데이터 확인 서비스 구축 <br/><strong>(에스알)',
  },
  {
    year: '2022',
    month: '06',
    content:
      '금융공공데이터 중 개인사업자정보 개방사업 용역 <br/><strong>(금융위원회)</strong><br/><br/>지역사랑상품권 가맹점 데이터 개방<br/><strong>(한국조폐공사)</strong>',
  },
  {
    year: '2022',
    month: '04',
    content: '아이돌봄 통합지원 플랫폼 구축사업 1단계<br/><strong>(여성가족부)</strong>',
  },
  {
    year: '2022',
    month: '01',
    content: "2021년 공공데이터포털 운영 및 유지관리('21~'22) <br/><strong>(한국지능정보사회진흥원)",
  },
];

const histories2021 = [
  {
    year: '2021',
    month: '12',
    content:
      '수요자 중심의 공공데이터 개방활용 체계 및 제공 기반 개선 <br/><strong>(한국지능정보사회진흥원)<br/><br/>철도 열차 승차권 진위확인 서비스 구축 <br/><strong>(한국철도공사)',
    isYearVisible: true,
  },
  {
    year: '2021',
    month: '08',
    content: '해외안전 융합정보 개방 확대<br/><strong>(외교부)</strong>',
  },
  {
    year: '2021',
    month: '05',
    content: '금융표준종합정보DB 개방확대 및 품질개선 <br/(금융위원회)',
  },
  {
    year: '2021',
    month: '03',
    content: '사업자등록정보 진위확인 서비스 구축<br/><strong>(국세청)</strong>',
  },
  {
    year: '2021',
    month: '01',
    content:
      "과기정통부 SW컴퓨팅산업원천기술개발(R&D, 정보화)사업 선정 '데이터 품질 평가기반 데이터 고도화 및 데이터셋 보정 기술 개발'<br/><br/>연구중심병원 육성 R&D 위탁과제 '지식DB 통계분석 및 시각화 시스템의 웹개발'<br/><strong>(서울대학교 산학협력단)</strong><br/><br/>보건의료기술개발사업 R&D 위탁과제 '대용량 병리 슬라이드 웹 서비스 고도화'<br/><strong>(서울대학교병원)</strong><br/><br/<br/>한국지능정보사회진흥원(NIA) 2021년 공공데이터포털 운영 및 유지관리(’21~’22) <br/><strong>(한국지능정보사회진흥원)",
  },
];

const histories2020 = [
  {
    year: '2020',
    month: '10',
    content:
      '한국지능정보사회진흥원(NIA) 개방형 응용프로그래밍 인터페이스 연계 및 관리체계 개선 <br/><strong>(한국지능정보사회진흥원)</strong><br/><br/>데이터SOS 체계 및 Open API 통합 서비스 제공 기반 구축 사업 <br/><strong>(한국지능정보사회진흥원)',
    isYearVisible: true,
  },
  {
    year: '2020',
    month: '07',
    content:
      '해외안전 융합정보 개방체계 구축 <br/><strong>(한국정보화진흥원)</strong><br/><br/>암단백유전체 임상데이터 연계활용 프레임워크 개발 <br/>(국립암센터)',
  },
  {
    year: '2020',
    month: '04',
    content:
      "과기정통부 SW컴퓨팅산업원천기술개발(R&D, 정보화)사업 선정 '데이터 품질 평가기반 데이터 고도화 및 데이터셋 보정 기술 개발'",
  },
  {
    year: '2020',
    month: '01',
    content:
      "공공데이터포털 및 범정부 데이터 플랫폼 통합 운영 유지보수 사업 <br/><strong>(한국정보화진흥원)</strong><br/><br/>연구중심병원 육성 R&D 위탁과제 '지식DB 통계분석 및 시각화 시스템의 웹개발'<br/><strong>(서울대학교 산학협력단)</strong><br/><br/>보건의료기술개발사업 R&D 위탁과제 '병리진단 AI 학습 플랫폼 운영 지원을 위한 개방형 웹 서비스 구축' <br/><strong>(서울대학교병원)</strong>",
  },
];

const histories2019 = [
  {
    year: '2019',
    month: '10',
    content: '빅데이터 플랫폼 연계활용 공통기반 구축 <br/><strong>(한국정보화진흥원)',
    isYearVisible: true,
  },
  {
    year: '2019',
    month: '08',
    content: '‘아일랜드 박스’ 홈페이지 구축 <br/><strong>(제주산학융합원, ㈜아이비제이)</strong>',
  },
  {
    year: '2019',
    month: '07',
    content: '우울증을 위한 모바일 앱 기반 수용 전념 심리 치료의 개발 <br/><strong>(아주대학교)</strong>',
  },
  {
    year: '2019',
    month: '06',
    content: '국가 암 병기조사 데이터 개방체계 구축 <br/><strong>(한국정보화진흥원, 국립암센터)</strong>',
  },
  {
    year: '2019',
    month: '05',
    content: '케이마린 홈페이지 업그레이드 용역 <br/><strong>(케이마린)</strong>',
  },
  {
    year: '2019',
    month: '04',
    content: '2019년 한국공학교육인증원 전산시스템 유지보수 <br/><strong>(한국공학교육인증원)</strong>',
  },
  {
    year: '2019',
    month: '02',
    content:
      '2019년도 공공데이터포털 (data.go.kr) 위탁 운영 및 서비스 개선사업 <br/><strong>(한국정보화진흥원)<br/><br/>AI 데이터 이용환경 (AIHub) 운영 및 활성화 지원<br/><strong>(한국정보화진흥원)</strong>',
  },
  {
    year: '2019',
    month: '01',
    content:
      "보건의료기술개발사업 R&D 위탁과제 '병리진단 AI 학습 플랫폼 운영 지원을 위한 개방형 웹 서비스 구축' <br/><strong>(서울대학교병원)</strong><br/><br/>연구중심병원 육성 R&D 위탁과제 '유전체 데이터베이스 저장 및 관리 시스템 개발' <br/><strong>(서울대학교 산학협력단)</strong>",
  },
];

const histories2018 = [
  {
    year: '2018',
    month: '10',
    content:
      "'서울시 빅데이터 통합저장소 기반의 데이터거버넌스 체계 컨설팅' 사업 전략 컨설팅 용역<br/><strong>(데이터스트림즈)</strong><br/><br/>AI 데이터 이용환경 운영 유지관리 사업 <br/><strong>(한국정보화진흥원)",
    isYearVisible: true,
  },
  {
    year: '2018',
    month: '09',
    content: '경기도 빅데이터 분석 및 활용 기반 강화 <br/><strong>(경기도)</strong>',
  },
  {
    year: '2018',
    month: '06',
    content:
      '한국정보화진흥원 빅데이터 포털 운영 및 유지보수<br/> <strong>(한국정보화진흥원)<br/><br/>전국 데이터베이스 시스템 구축 및 운영<br/> <strong>(한국정보화진흥원)',
  },
  {
    year: '2018',
    month: '04',
    content: '빅데이터 플랫폼 및 오픈API 포털 구축<br/> <strong>(한국정보화진흥원)',
  },
  {
    year: '2018',
    month: '02',
    content:
      '한국정보화진흥원 공공데이터 개방 플랫폼 운영 및 유지보수 <strong>(한국정보화진흥원)<br/><br/>공공데이터 개방 및 활용 기반 구축<br/> <strong>(한국정보화진흥원)',
  },
];

const historiesEtc = [
  {
    year: '2017',
    month: '12',
    content: "산학연협력 기술개발사업(연구마을과제) 선정 '빅데이터 비식별화 및 유용성 평가를 위한 기술 개발'",
    isYearVisible: true,
  },
  {
    year: '2017',
    month: '11',
    content: 'IoMT 표준 연동형 네트워크 디스플레이 시작품 제작비 <br/><strong>(명지대학교 산학협력단)</strong>',
  },
  {
    year: '2017',
    month: '10',
    content:
      "2017년 창업도약패키지 지원사업 선정 'LiveBoard: 인터랙티브 모션 포스터 스마트사이니지 서비스'<br/><br/>웹 기반 API 개발/실행/등록/배포 환경 프로토타입 개발 <br/><strong>(한국전자통신연구원)</strong>",
  },
  {
    year: '2017',
    month: '08',
    content:
      '설문조사 App 개발 기획 및 디자인 <br/><strong>(명지대학교 산학협력단)</strong><br/><br/>공공데이터 홈페이지 4종 통합 개선 및 유지관리 용역 <br/><strong>(한국정보화진흥원)',
  },
  {
    year: '2017',
    month: '07',
    content:
      '인공지능 객체인식 기반 대형폐기물 처리 시스템 구축 <br/><strong>(한국정보화진흥원)<br/><br/>해외 개인정보 침해 피해구제 상담 매뉴얼 개발 <br/><strong>(한국인터넷진흥원)</strong>',
  },
  {
    year: '2017',
    month: '06',
    content:
      '개인정보 비식별 조치 활성화 기반 조사<br/><strong>(한국인터넷진흥원)</strong><br/><br/>온실 맞춤형 모바일 앱 개발<br/><strong>(한국과학기술연구원)</strong>',
  },
  {
    year: '2017',
    month: '03',
    content:
      '『역량기반교육진단시스템 보완·개선』위탁용역 계약 <br/><strong>(미래창조과학부)</strong><br/><br/>해양수질자동측정망 운영시스템 유지보수 <br/><strong>(해양환경관리공단)</strong><br/><br/>헬시하트시스템 유지보수 <br/><strong>(고령군보건소)</strong>',
  },
  {
    year: '2017',
    month: '01',
    content:
      '2016~2017년도 공공데이터포털(data.go.kr)위탁 운영 및 서비스 운영개선 사업 2차년도 <br/><strong>(NIA)</strong>',
  },
  {
    year: '2016',
    month: '12',
    content: "산학연협력 기술개발사업(연구마을과제) 선정 '빅데이터 비식별화 및 유용성 평가를 위한 기술 개발'",
    isYearVisible: true,
  },
  {
    year: '2016',
    month: '10',
    content:
      'NIA 개도국 정보접근센터 재구축<br/><br/>가이온 “정보화진흥원-신한카드” 프로젝트 UI/UX디자인·Publishing 수행',
  },
  {
    year: '2016',
    month: '08',
    content: '중소기업청, 2016년 기술혁신개발사업 선정 “IoT 기술기반의 쌍방향 병원 입원환자 식별 및 안전관리 시스템”',
  },
  {
    year: '2016',
    month: '07',
    content:
      '쌍용자동차 일반정비포털시스템 구축<br/><br/>KIST 모바일 스마트팜 앱 개발<br/><br/>환경부 생활환경 안전정보 통합관리 시스템 구축',
  },
  {
    year: '2016',
    month: '05',
    content: '상명대학교 천안산학협력단과 MOU 체결',
  },
  {
    year: '2016',
    month: '04',
    content:
      '중소기업청, 2016년 기업서비스 연구개발 사업 선정 “디지털 사이니지 기반 서비스 우네보: 우리는 네가 무엇을 보고 있는지 알고 있다!”',
  },
  {
    year: '2016',
    month: '03',
    content: '『홍대 카툰네트워크 MC보드 서비스 계약',
  },
  {
    year: '2016',
    month: '01',
    content:
      '제주대학교와 MOU 체결<br/><br/>우즈벡거래소 홈페이지 재구축<br/><br/>정보화진흥원 공공데이터포털 유지보수',
  },
  {
    year: '2015',
    month: '12',
    content: '쇼퍼라마 앱개발<br/><br/>BnBHero Booking.com 연계 개발',
    isYearVisible: true,
  },
  {
    year: '2015',
    month: '11',
    content: '한국공학교육인증원 인증평가지원시스템(AMS) 고도화<br/><br/>정보보호지원센터 홈페이지 개선',
  },
  {
    year: '2015',
    month: '10',
    content: '미래부 역량진단 평가시스템 구축',
  },
  {
    year: '2015',
    month: '09',
    content: '명지대 산업경영공학과 홈페이지<br/><br/>국방부 성폭력 신고/상담 앱개발',
  },
  {
    year: '2015',
    month: '08',
    content: '필리핀 Globe Telecom Oracle OAuth Service<br/><br/>괴산 세계 유기농 산업엑스포 모바일 앱개발(Android)',
  },
  {
    year: '2015',
    month: '07',
    content: '국립과천과학관 홈페이지 개선 사업',
  },
  {
    year: '2015',
    month: '05',
    content:
      '외국관광객을 위한 다국어 기반 핀테크 연동형 모바일웹 주문결제시스템<br/><br/>형지패션그룹 스마트빌딩보드 계약<br/><br/>래몽래인C&C 쇼퍼라마 모바일 웹 데모버전 개발<br/><br/>형지패션그룹 MC보드 계약',
  },
  {
    year: '2015',
    month: '04',
    content:
      '병역특례 업체 지정(전문연구요원, 15년도 상반기 중소기업 부설 연구소 지정업체 선정)<br/><br/>대양정보통신 헬시하트시스템 유지보수 계약',
  },
  {
    year: '2015',
    month: '02',
    content: '명지대학교 에너지융합공학과 홈페이지 개발<br/><br/>(주)씨씨파트너스 BnBHero 웹사이트 유지보수계약 연장',
  },
  {
    year: '2015',
    month: '01',
    content: '래몽래인 웹사이트 구축',
  },
  {
    year: '2014',
    month: '12',
    content: '아워홈 루 MC패드 계약<br/><br/>주식회사 아름 MC보드 계약',
    isYearVisible: true,
  },
  {
    year: '2014',
    month: '11',
    content: '팝바 MC보드 서비스 계약',
  },
  {
    year: '2014',
    month: '10',
    content: '예미원피부과 홍보영상 제작',
  },
  {
    year: '2014',
    month: '08',
    content:
      '정성맛 외식산업 카페 및 해물식당 네이밍&BI 제작<br/><br/>지구화학 웹사이트 구축<br/><br/>REVE21 영상편집 제작<br/><br/>온실가스검증원 홈페이지 개편',
  },
  {
    year: '2014',
    month: '07',
    content: '머큐어 앰버서더 강남 MC패드 서비스 계약<br/><br/>제니스글로벌 샨띠치유요가 MC보드 서비스계약',
  },
  {
    year: '2014',
    month: '06',
    content: '명지대학교 창업보육센터 홈페이지 구축',
  },
  {
    year: '2014',
    month: '05',
    content:
      '더하다 MC 패드 서비스 계약<br/><br/>투썸 모란점 MC 보드 서비스 계약<br/><br/>알코올(모란점) MC 보드 서비스 계약<br/><br/>강남8258빌딩정보 디스플레이 계약<br/><br/>옐로우폰케이스 리플렛 제작',
  },
  {
    year: '2014',
    month: '04',
    content: '속초 썸 MC오더 서비스 계약',
  },
  {
    year: '2014',
    month: '03',
    content:
      '크롬바커 웹사이트 구축<br/><br/>케이비코리아(주) 홈페이지 구축<br/><br/>곰코피 BI 디자인<br/><br/>Anybean Coffee 브랜드 및 로고 디자인<br/><br/>조선옥 MC보드 서비스 계약<br/><br/>곰코피 BI 디자인',
  },
  {
    year: '2014',
    month: '02',
    content:
      '서래맥주집 맥주집 MC보드 서비스 계약<br/><br/>서래불고기 맥주집 MC보드 서비스 계약<br/><br/>서래갈매기 MC보드 서비스 계약<br/><br/>와바 영통점 MC보드 서비스 계약<br/><br/>렉스코스 터치스크린 제어 소프트웨어 개발<br/><br/>(주)씨씨파트너스 BnBHero 웹사이트 유지보수 계약',
  },
  {
    year: '2014',
    month: '01',
    content: '가인미가 MC보드 서비스 계약<br/><br/>어썸 부평점 MC보드 서비스 계약',
  },
  {
    year: '2013',
    month: '12',
    content:
      "미래부 '모바일 융합형 애플리케이션 및 그래픽 유저인터페이스 검증을 위한 원격 자동화 테스팅플랫폼'모바일융합기술센터구축사업 선정",
      isYearVisible: true,
  },
  {
    year: '2013',
    month: '11',
    content: '투썸플레이스 망포역점 MC보드 서비스 계약',
  },
  {
    year: '2013',
    month: '10',
    content: '정성맛외식산업(주) 디지털 메뉴보드 디자인 계약',
  },
  {
    year: '2013',
    month: '08',
    content: '기업부설연구소 인증',
  },
  {
    year: '2013',
    month: '07',
    content: '경기중소기업청 “클라우드 기반 맞춤형 디지털 메뉴보드 및 광고 시스템” 산학연 과제 선정',
  },
  {
    year: '2013',
    month: '05',
    content:
      "'효율적인 온톨로지 검색과 추론을 위한 인덱스' 논문 게재(한국전자거래학회지 제 18권 2호)영원 메뉴클라우드 MC보드 서비스 계약",
  },
  {
    year: '2013',
    month: '04',
    content:
      '더하다 메뉴클라우드 MC 패드 Standard 서비스 계약<br/><br/>(주)대양이티엔씨 유비무환 모바일 앱 구축<br/>(아바타 내용 포함)',
  },
  {
    year: '2013',
    month: '03',
    content: '(주)대양이티엔씨 영양제안을 위한 연동형 전자메뉴 시스템 개발',
  },
  {
    year: '2013',
    month: '01',
    content:
      '(주)명문투자개발 리라스쿨 웹사이트 구축<br/><br/>케이비코리아 크롬바커 홈페이지 제작 및 메뉴클라우드 서비스 계약',
  },
  {
    year: '2012',
    month: '12',
    content: '정성맛외신산업(주) 868식당 홈페이지 제작 및 메뉴클라우드 서비스 계약',
    isYearVisible: true,
  },
  {
    year: '2012',
    month: '08',
    content: '벤처기업 등록 (벤처기업확인서)',
  },
  {
    year: '2012',
    month: '04',
    content: '(주)씨씨파트너즈 BnBHero 웹서비스 개발 계약',
  },
  {
    year: '2012',
    month: '02',
    content: '인하대학교 GNSS 전문이력 양성사업단 홈페이지 구축',
  },
  {
    year: '2011',
    month: '10',
    content:
      '(주)보노비젼 쇼퍼라마: 영상기반 쇼핑 솔루션 개발<br/><br/>(주)대양이티엔씨 개인화된 웰니스 데이터 통합을 위한 데이터베이스분석 및 설계 위탁 개발<br/><br/>스타샷 아이폰 용 앱 출시',
      isYearVisible: true,
  },
  {
    year: '2011',
    month: '06',
    content: '(주)대양이티엔씨 식물공장 원격제어 모듈 설계 및 구현 개발',
  },
  {
    year: '2011',
    month: '05',
    content: '(주)PROMPT 데이터베이스 컨설팅 계약<br/><br/>NHN(주) Android 모바일 앱 용역 개발',
  },
  {
    year: '2011',
    month: '04',
    content: '서울대학교병원 전자임상시험자료관리시스템을 통한 e-CT 기반 기술 평가 및 검증 연구<br/><br/>법인설립',
  },
];

export const histories: History[] = [
  ...histories2024,
  ...histories2023,
  ...histories2022,
  ...histories2021,
  ...histories2020,
  ...histories2019,
  ...histories2018,
  ...historiesEtc,
];
