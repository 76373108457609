import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography } from '@mui/material';

import dataInfuserLogoImage from '@/assets/data-finuser-logo-black.png';
import dataInfuserDescImage from '@//assets/product/data-infuser-desc1.png';

const ProductInfuser: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container className="product-section infuser-desc-grid" spacing={3}>
      <Grid item xs={12} sm={6} md={6} lg={5}>
        <Box className="infuser-desc-box">
          <Box className="infuser-desc-title">데이터 인퓨저란?</Box>
          <Box className="infuser-desc-row">
            <Typography className="infuser-desc-sub">
              당사의 데이터 인퓨저는 다양한 원천 데이터를 손쉽게 API로 전환하는 &nbsp;
            </Typography>
            {/* <br /> */}
            <Typography className="infuser-desc-sub bold-word">오픈소스 기반 API 관리 시스템</Typography>
            <Typography className="infuser-desc-sub">입니다.</Typography>
          </Box>
          <Box className="infuser-desc-img-box">
            <img src={dataInfuserLogoImage} className="infuser-logo-img" />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={7}>
        <Box className="infuser-desc-img">
          <img src={dataInfuserDescImage} className="infuser-desc-img" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProductInfuser;
