import { Box, Container, Grid, Stack } from '@mui/material';
import HomeSubNavigator from './HomeSubNavigator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faDocker } from '@fortawesome/free-brands-svg-icons';
import dataInfuserImage from '../../assets/imgs/home/data_infuser_archi.png';
import dataInfuserLogoImage from '../../assets/imgs/home/data_infuser_logo.png';

export default function DataInfuser() {
  return (
    <div className="home-item data-infuser">
      <Container maxWidth="xl">
        <Grid container>
          <Grid item lg={4} xs={12} order={{ xs: 2, lg: 1 }}>
            <Stack className="infuser-features m-pt-0">
              <Box order={{}}>
                <div className="infuser-feature">
                  <div className="infuser-feature-icon">
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </div>
                  <div className="infuser-feature-text">GUI 기반의 손쉬운 API 작성</div>
                </div>
              </Box>
              <Box order={{}}>
                <div className="infuser-feature">
                  <div className="infuser-feature-icon">
                    <FontAwesomeIcon icon={faChartLine} />
                  </div>
                  <div className="infuser-feature-text">다양한 데이터 소스 지원</div>
                </div>
              </Box>
              <Box order={{}}>
                <div className="infuser-feature">
                  <div className="infuser-feature-icon">
                    <FontAwesomeIcon icon={faDocker} />
                  </div>
                  <div className="infuser-feature-text">컨테이너 방식의 손쉬운 설치</div>
                </div>
              </Box>
              <Box display={'flex'} justifyContent={'center'} order={{}}>
                <Box
                  component={'img'}
                  className="data-infuser-logo"
                  alignSelf={{
                    md: 'center',
                  }}
                  sx={{ width: { xs: '80%', lg: '100%' } }}
                  src={dataInfuserLogoImage}
                  loading="lazy"
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} md={12} lg={8} order={{ xs: 1, lg: 2 }}>
            <Box component={'div'} display={'flex'} justifyContent={'center'}>
              <Box
                component={'img'}
                alignSelf={{
                  md: 'center',
                }}
                sx={{ width: { xs: '80%', lg: '100%' } }}
                src={dataInfuserImage}
                loading="lazy"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
