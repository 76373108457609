import { Box, Container, Grid } from '@mui/material';

import dataVerifierBgImg from '@/assets/imgs/home/ai_data_verifier_slide_bg_v2.png';
import HomeSubNavigator from './HomeSubNavigator';

export default function DataVerifier() {
  return (
    <Box
      component={'div'}
      className={'home-item bigdata'}
      sx={{
        backgroundImage: `url(${dataVerifierBgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top -80px',
      }}
    >
      <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box>
          <Box className={'t-61 bigdata-title'}>AI Data Verifier</Box>
          <Box className={'t-18 bigdata-desc'} sx={{ textAlign: 'right' }}>
            <Box>Big Data와 AI를 활용한</Box>
            <Box>데이터 검증 시스템</Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
