import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem } from '@mui/material';
import infuserGatewayImg from '@/assets/product/infuser/executor-bg.png';
import ModuleIcon from '@/assets/icon/module.svg';
import KeyIcon from '@/assets/icon/key.svg';
import GraphIcon from '@/assets/icon/graph.svg';
import DocIcon from '@/assets/icon/doc.svg';
import IconBox from 'src/components/iconbox/IconBox';

const DataInfuserComponentExecutor: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Grid container spacing={3} direction={isMobile ? 'column-reverse' : 'row'}>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Box className={`bg-item ${isMobile ? 'block-sm' : ''}`}>
            <Grid container spacing={1}>
              <IconBox
                icon={ModuleIcon}
                text={
                  <>
                    REST
                    <br />
                    서비스 모듈
                  </>
                }
              />
              <IconBox
                icon={KeyIcon}
                text={
                  <>
                    파라미터
                    <br />
                    검증 모듈
                  </>
                }
              />
              <IconBox
                icon={GraphIcon}
                text={
                  <>
                    Request
                    <br />
                    분석 모듈
                  </>
                }
              />
              <IconBox
                icon={DocIcon}
                text={
                  <>
                    데이터
                    <br />
                    조회 모듈
                  </>
                }
              />
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={6}>
          <Box className="word-20 fw-4">
            Executor는 <strong>사용자의 요청을 실행하고 필요한 데이터를 가져오는 서비스</strong>입니다.
            <br />
            <br />
            사용자의 요청 정보로부터 메타데이터를 추출하여 메타데이터 DB에 질의합니다. 이후 DB 쿼리를 사용하여 Open Data
            DB의 API를 질의하고 필요한 정보를 찾으면 해당 정보를 Gateway로 전달합니다.
            <br />
            <br />
            Gateway는 API를 JSON/XML 형식으로 사용자에게 반환합니다.
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Box className="img-bg-box">
            <Box className={`bg-item white word-20 p1-20`}>EXECUTOR(Open API 서비스 구동 모듈)</Box>
            <img src={infuserGatewayImg} alt="Data Infuser Manager" className="data-infuser-manager" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataInfuserComponentExecutor;
