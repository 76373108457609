import { Box } from '@mui/material';

export interface FeatureItemProps {
  bgImgUrl: string;
  title: string;
  content: string;
}

export default function FeatureItem(props: FeatureItemProps) {
  return (
    <Box
      component="div"
      className="feature-item"
      sx={{
        backgroundImage: `url(${props.bgImgUrl})`,
      }}
    >
      <div className="feature-content-wrap">
        <div className="title">{props.title}</div>
        <div className="content">{props.content}</div>
      </div>
    </Box>
  );
}
