import { createTheme } from '@mui/material/styles';

// A custom theme for this app
let theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0067FF',
      light: '#CCE8FF',
    },
    success: {
      main: '#009067',
      light: '#CCE8FF',
    },
    warning: {
      main: '#F2B807',
      light: '#FEF7CC',
    },
  },
  typography: {
    fontFamily: ['pretendard'].join(','),
    fontSize: 14,
  },
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          '&.Mui-disabled': {
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          },
        },
      },
    },
  },
});

theme = createTheme(theme, {
  palette: {
    danger: theme.palette.augmentColor({
      color: { main: '#F25746', light: '#FEE9DA' },
      name: 'danger',
    }),
    point: theme.palette.augmentColor({
      color: { main: '#D03FF1', light: '#FED8F9' },
      name: 'point',
    }),
    grey: theme.palette.augmentColor({
      color: { main: '#8494AE', light: '#EFEFF2' },
      name: 'grey',
    }),
  },
});

export default theme;
