import protectImage from '../../assets/certificates/8/protect.png';
import datasetImage from '../../assets/certificates/7/dataset.png';
import graphImage from '../../assets/certificates/6/graph.png';
import contentsImage from '../../assets/certificates/5/contents.png';
import pivotImage from '../../assets/certificates/4/pivot.png';
import improveHypercubeImage from '../../assets/certificates/3/improve-hypercube.png';
import communicateImage from '../../assets/certificates/2/communicate.png';
import hypercubeImage from '../../assets/certificates/1/hypercube.png';

export const certificates = [
  {
    title: '안전한 분산 집합정보물 결합 방법',
    image: protectImage,
    no: '제 10-2382314호',
    author: '전종훈',
    registeredAt: '2022-03-30',
  },
  {
    title: '데이터셋 관리 장치 및 방법',
    image: datasetImage,
    no: '제 10-2100346호',
    author: '전종훈',
    registeredAt: '2022-04-07',
  },
  {
    title: '삼분 그래프에 기반한 추천 방법',
    image: graphImage,
    no: '제 10-1985603호',
    author: '전종훈',
    registeredAt: '2019-05-28',
  },
  {
    title: '컨텐츠 융합형 동적 광고 시스템',
    image: contentsImage,
    no: '제 10-1945726호',
    author: '전종훈',
    registeredAt: '2019-01-30',
  },
  {
    title: '안전한 분산 집합정보물 결합 방법',
    image: pivotImage,
    no: '제 10-2382314호',
    author: '전종훈',
    registeredAt: '2022-03-30',
  },
  {
    title: '피봇언어를 사용한 음식점 메뉴 번역 방법',
    image: improveHypercubeImage,
    no: '제 10-1683801호',
    author: '전종훈',
    registeredAt: '2016-12-01',
  },
  {
    title: '무선통신을 이용한 신호등 정보 제공 시스템',
    image: communicateImage,
    no: '제 10-1352971호',
    author: '전종훈',
    registeredAt: '2014-01-13',
  },
  {
    title: '하이퍼 큐브를 사용한 추론 검색 방법',
    image: hypercubeImage,
    no: '제 10-1318250호',
    author: '전종훈',
    registeredAt: '2013-10-08',
  },
];
