import { Container } from '@mui/material';
import scrollDownArrowImage from '../../assets/imgs/home/scroll_down_icon.png';
import useIsMobile from '@/components/mobile/useInMobile';

interface ScrollHelperProps {
  currentIndex: number;
}

export default function ScrollHelper(props: ScrollHelperProps) {
  const { currentIndex } = props;

  const isMobile = useIsMobile();

  let scrollStyleClass = '';
  if (isMobile === true) {
    scrollStyleClass = 'hide';
  } else if (currentIndex === 0) {
    scrollStyleClass = 'scroll-center first-page';
  } else if (currentIndex === 1) {
    scrollStyleClass = 'text-gray';
  } else if (currentIndex === 7) {
    scrollStyleClass = 'hide';
  }

  return (
    <div className={`scroll-down-wrap ${scrollStyleClass}`}>
      <Container maxWidth="xl">
        <div className="scroll-box">
          <div className="scroll-down-arrow">
            <img src={scrollDownArrowImage} />
          </div>
          <div>SCROLL DOWN</div>
          <div className="scroll-icon-box">
            <span className="scroll-bullet"></span>
          </div>
        </div>
      </Container>
    </div>
  );
}
