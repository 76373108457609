import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography } from '@mui/material';

import DataVerifierMain from './data_verifier/DataVerifierMain';
import BannerImage from '@/assets/imgs/products/ai_data_verifier_banner.png';
import TopBanner from '@/components/product/TopBanner';
import BannerTitleImage from '@/assets/imgs/products/ai_data_verifier_banner_title.png';

const DataVerifier: React.FC = () => {
  return (
    <Box>
      <TopBanner bgImg={BannerImage} typeImg={BannerTitleImage} title="AI Data Verifier" desc="AI 데이터 베리파이어" />
      <Container maxWidth="xl">
        <DataVerifierMain />
      </Container>
    </Box>
  );
};

export default DataVerifier;
