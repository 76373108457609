import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem, Divider } from '@mui/material';
import bigdataBgImg from '@/assets/imgs/home/bigdata_slide_bg.png';

const OrganizationCharacteristic: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Box className="product-infuser-character-header-box">
        <Typography className="word-47">사업분야</Typography>
      </Box>
      <Box className="infuser-desc-row">
        <Typography className="company-desc-sub">
          당사는 창립 이래, 데이터 분야의
          <br />
        </Typography>
        <Typography className="company-desc-sub bold-word" display="inline">
          다양한 경험과 기술
        </Typography>
        <Typography className="company-desc-sub" display="inline">
          을 확보한
        </Typography>
        <Typography className="company-desc-sub bold-word" display="inline">
          빅데이터 전문기업
        </Typography>
        <Typography className="company-desc-sub" display="inline">
          입니다.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box className="product-infuser-char-box box1">
            {/* <Box className="product-infuser-char-box company-box1"> */}
            <Box className="title white-color-title">
              개방DB 및
              <br />
              Open API 구축 전문성
            </Box>
          </Box>
          <Box>
            <Typography className="ul-desc">
              <List
                sx={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside',
                }}
              >
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    금융위원회, 외교부, 한국조폐공사 등 다수의&nbsp;
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                    국가중점데이터 개방DB&nbsp;
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    및&nbsp;
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                    Open API&nbsp;
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    구축
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                    오픈소스 기반 Open API 시스템 (Data Infuser) 개발기업
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    으로 14개 공공기관의 오픈API 서비스에 적용 중
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                    재난상황 등 신속한 정보 개방이 요구되는&nbsp;
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    공적마스크 판매 현황 API, 요소수 재고 API 긴급서비스 개발&nbsp;
                  </Typography>
                  <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                    등 다양한 사업 수행
                  </Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                  <Typography variant="inherit" display="inline" className="ul-desc">
                    공공·민간 10개 분야 플랫폼과 113개소 센터 간의 연계를 위해, 공공데이터포털 구축 및 운영 기술
                  </Typography>
                </ListItem>
              </List>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box className="product-infuser-char-box box2">
            {/* <Box className="product-infuser-char-box company-box2"> */}
            <Box className="title white-color-title">
              공공데이터포털
              <br />
              구축 및 운영 기술
            </Box>
          </Box>
          <Box>
            <List
              sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside',
              }}
            >
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  국내 최대 공공데이터 개방 포털인 공공데이터포털&nbsp;
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  2016년~2024년 현재 운영
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  &nbsp;및&nbsp;
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  구축 사업자
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  5천여개 기관
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  들과의 데이터 등록,&nbsp; 시스템 연계 운영
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  2021년 3월
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  부터&nbsp;
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  멀티 클라우드 기반 연계 정책
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  &nbsp;실시
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  공공빅데이터 저장,&nbsp;관리 및 조회, Open API 연계,&nbsp;시각화 서비스 구축 및 운영
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  공공데이터 등록
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  &nbsp;규격 마련 및&nbsp;지침서 제공
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Box className="product-infuser-char-box box3">
            {/* <Box className="product-infuser-char-box company-box3"> */}
            <Box className="title white-color-title">
              민간 및 공공 클라우드
              <br />
              기반 시스템 구축 기술
            </Box>
          </Box>
          <Box>
            <List
              sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside',
              }}
            >
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  하이브리드(민간+공공) 클라우드
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  &nbsp; 기반 시스템 구축 전문성
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  클라우드 네이티브&nbsp;
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  기술 전문성
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'list-item' }}>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  민간 클라우드 기반&nbsp;
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc bold-word-20">
                  데이터 체계 및 Open API 통합 서비스 &nbsp;
                </Typography>
                <Typography variant="inherit" display="inline" className="ul-desc">
                  구축
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganizationCharacteristic;
