import bannerArrowBtn from '@/assets/banner/di_button_arrow.png';
import bannerInfuser from '@/assets/banner/di_img01.png';
import bannerInfuserInfo from '@/assets/banner/di_img02.png';
import bannerLogo from '@/assets/banner/logo.png';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

export default function InfuserBanner() {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      justifyContent={{ xs: 'center', md: 'left' }}
      textAlign={{ xs: 'center', md: 'left' }}
    >
      <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }} pb={{ xs: 2 }}>
        <Stack
          spacing={{ xs: 3, lg: 4 }}
          sx={{
            fontSize: '18px',
            height: '100%',
          }}
        >
          <Box component={'div'}>
            <Typography
              sx={{
                color: '#bf6764',
                fontWeight: 'bold',
              }}
            >
              Prompt Technology - API Management Solution
            </Typography>
          </Box>
          <Box component={'div'}>
            <Box component={'img'} src={bannerLogo} sx={{ maxWidth: '290px' }}></Box>
          </Box>
          <Box
            component={'span'}
            sx={{
              color: '#8e8e8e',
              fontWeight: 400,
            }}
          >
            파일, DBMS 등 다양한 원천 데이터를 손쉽게 API로
            <br />
            전환하는{' '}
            <Box component={'span'} fontWeight={'bold'}>
              오픈소스 기반 API 관리 시스템{' '}
            </Box>
            입니다.
          </Box>
          <Box component={'div'}>
            <Box component={'img'} src={bannerInfuserInfo} sx={{ maxWidth: '290px' }}></Box>
          </Box>
          <Box component={'div'}>
            <Button
              variant="contained"
              sx={{
                borderRadius: '40px',
                width: '275px',
                fontSize: '18px',
              }}
            >
              GitHub 바로가기
              <Box
                component={'img'}
                src={bannerArrowBtn}
                // pt={'10px'}
                pb={'10px'}
                pl={1}
                mb={1}
                sx={{
                  width: 'auto !important',
                }}
              ></Box>
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} md={7} order={{ xs: 1, md: 2 }}>
        <Box component={'img'} src={bannerInfuser}></Box>
      </Grid>
    </Grid>
  );
}
