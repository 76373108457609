import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Divider } from '@mui/material';

import HistoryMain from './HistoryMain';
import TopBanner from '@/components/product/TopBanner';
import BannerImage from '@/assets/history/history_image.png';

const History: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <TopBanner bgImg={BannerImage} title="주요 연혁" desc="프람트테크놀로지의 연혁을 모아 보여드립니다." />
      <Container maxWidth="xl">
        <HistoryMain />
      </Container>
    </Box>
  );
};

export default History;
