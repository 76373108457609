import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography } from '@mui/material';
import DataInfuserComponentGateWay from './DataInfuserComponentGateWay';
import DataInfuserComponentDesigner from './DataInfuserComponentDesigner';
import DataInfuserComponentLoader from './DataInfuserComponentLoader';
import DataInfuserComponentManager from './DataInfuserComponentManager';
import DataInfuserComponentExecutor from './DataInfuserComponentExecutor';

const DataInfuserComposition: React.FC = () => {
  return (
    <Box>
      <Typography className="word-47 main-color">Data Infuser 구성요소</Typography>
      <DataInfuserComponentGateWay />
      <DataInfuserComponentDesigner />
      <DataInfuserComponentLoader />
      <DataInfuserComponentExecutor />
      <DataInfuserComponentManager />
    </Box>
  );
};

export default DataInfuserComposition;
