import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import { Slide } from 'react-full-page';

import mainBg from '@/assets/imgs/home/main_slide_bg.png';

import styles from './home.module.css';

export default function MainSlide() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${mainBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <Stack
          sx={{
            minHeight: '100vh',
            justifyContent: 'center',
          }}
        >
          <Box component={'div'} height="50vh" className={`${styles.title}`}>
            <Box component={'div'}>
              <Box component={'span'} className={`${styles.bold}`}>
                빅데이터 기반
              </Box>
              의 혁신적인 솔루션으로
            </Box>
            <Box component={'div'}>공공데이터를 새롭게 정의합니다.</Box>
          </Box>

          <Box component="div" height="50vh" className={`${styles.sub}`}>
            <Box component="div">
              <Box component={'span'} className={`${styles.bold}`}>
                프람트테크놀로지
              </Box>
              는 더 나은 내일을 위해 노력합니다.
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
