import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Box, Typography, Divider, Modal } from '@mui/material';
import { certificates } from '../../components/data/Certificates';

const PatentCertificate: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleOpen = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Container className="patent-container">
      <Grid container spacing={3}>
        {certificates.map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
            <Box className="patent-box certificate-box">
              <Box className="title-box">
                <Typography className='title-text'>{item.title}</Typography>
              </Box>
              <Divider className="patent-divider" />
              <Box className="content-box">
                <Box className="content-item">
                  등록번호 : <strong>{item.no}</strong>
                </Box>
                <Box className="content-item">
                  저작자 : <strong>{item.author}</strong>
                </Box>
                <Box className="content-item">
                  등록일 : <strong>{item.registeredAt}</strong>
                </Box>
              </Box>
              <Box className="content-image" onClick={() => handleOpen(item.image)}>
                <img src={item.image} alt={item.title} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Modal className="patent-modal" open={open} onClose={handleClose}>
        <Box className="modal-content">
          <img src={selectedImage} alt="Selected" style={{ width: '300px', height: 'auto' }} />
        </Box>
      </Modal>
    </Container>
  );
};

export default PatentCertificate;
