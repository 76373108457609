import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem, Divider } from '@mui/material';
import dataInfuserManager from '@/assets/product/data-finsuer-designer.png';
import startDoubleQuotation from '@/assets/quotation/start-double-quotation.svg';
import endDoubleQuotation from '@/assets/quotation/end-double-quotation.svg';
import dataInfuserdesc from '@/assets/product/data-infuser-desc2.png';

const ProductInfuserDifferentiation: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Grid container spacing={4} className="content-box">
        <Grid item xs={12}>
          <Box className="product-infuser-character-header-box">
            <Typography className="word-47 pb-48 main-color">Data Infuser의 특장점</Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Box className="product-infuser-char-box box1">
            <Box className="title white-color-title">API 즉시 생성</Box>
          </Box>
          <Box className="product-infuser-desc-box">
            <Box className="title">
              Data Infuser를 사용하면 코딩할 필요없이 GUI 기반의 간단한 조작만으로 즉시 API를 생성하고 관리할 수
              있습니다.
              <br />
              <br />
              원하는 데이터 파일을 선택하고 간단한 클릭으로 파일을 업로드 할 수 있고, 파일 내용은 자동으로
              데이터베이스에 입력됩니다.
              <br />
              <br />
              Data Infuser로 간편한 작업을 통해 자신만의 API를 만들 수 있습니다.
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Box className="product-infuser-char-box box2">
            <Box className="title white-color-title">자동화된 API 문서</Box>
          </Box>
          <Box className="product-infuser-desc-box">
            <Box className="title">
              API를 사용하기 전에 API 명세를 읽는 것이중요합니다. Data Infuser는 Swagger를 통해 API 문서를 자동으로
              제공합니다.
              <br />
              <br />
              Swagger의 대화형 UI는 API 작업에 대한 시각화를 제공하여 독자가 API 작동 방식을 이해하는 데 도움이 됩니다.
              <br />
              <br />
              API 시스템을 완벽하게 이해하지 못하더라도 API 문서를 읽고 나면 API를 구축할 수 있습니다.
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Box className="product-infuser-char-box box3">
            <Box className="title white-color-title">다양한 데이터 소스 수집</Box>
          </Box>
          <Box className="product-infuser-desc-box">
            <Box className="title">
              사용자가 파일 업로더에 파일(CSV, XLSX 등) 또는 DBMS(Oracle, MySQL, MariaDB, CUBRID, Tibero 등)를 삽입하면
              Data Infuser는 각 파일 형식에 따라 적절하게 데이터를 추출합니다.
              <br />
              <br />
              데이터 소스의 내용이 변경되면 Data Infuser의 스케줄러는 자동으로 데이터를 수집하고 정기적으로
              업데이트합니다
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Box className="product-infuser-char-box box4">
            <Box className="title white-color-title">마이크로서비스아키텍쳐</Box>
          </Box>
          <Box className="product-infuser-desc-box">
            <Box className="title">
              Data Infuser는 트래픽이 많은 경우에도 안정적인 서비스를 제공합니다.
              <br />
              <br />각 서비스의 독립성은 일관된 배포와 탄력적인 서버 제어를 가능하게 합니다.
              <br />
              <br />
              서비스 내의 각 모듈은 HTTP 2.0 기반의 RPC를 사용하여 통신을 촉진하므로 전체적으로 더 가볍고 빠른 성능을
              제공합니다.
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductInfuserDifferentiation;
