import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styles from './IconBox.module.css';

interface IconBoxProps {
  icon: string;
  text: React.ReactNode;
}

const IconBox: React.FC<IconBoxProps> = ({ icon, text }) => (
  <Grid item xs={3} sm={3} md={3} lg={6}>
    <Box className="img-icon-box">
      <img src={icon} />
      {text}
    </Box>
  </Grid>
);

export default IconBox;
