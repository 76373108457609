import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography } from '@mui/material';

import DataInfuserMain from './data_infuser/DataInfuserMain';
import DataInfuserDifferentiation from './data_infuser/DataInfuserDifferentiation';
import DataInfuserCharacteristic from './data_infuser/DataInfuserCharacteristic';
import BannerImage from '@/assets/imgs/products/data_infuser_banner.jpg';

import TopBanner from '@/components/product/TopBanner';
import BannerTitleImage from '@/assets/imgs/products/data_infuser_banner_title.png';
import DataInfuserComposition from './data_infuser/DataInfuserComposition';

const DataInfuser: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <TopBanner bgImg={BannerImage} typeImg={BannerTitleImage} title="Data Infuser" desc="데이터 인퓨저" />
      <Container maxWidth="xl">
        <DataInfuserMain />
        <DataInfuserDifferentiation />
        <DataInfuserCharacteristic />
        <DataInfuserComposition/>
      </Container>
    </Box>
  );
};

export default DataInfuser;
