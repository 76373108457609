import { Box, Container, useMediaQuery, useTheme, Grid, Divider } from '@mui/material';
import isMobile from '../../components/mobile/useInMobile';

export interface HomeSubNavigatorProps {
  active?: string;
  moveSlide: (slideIndex: number) => void;
}

export default function HomeSubNavigator(props: HomeSubNavigatorProps) {
  return (
    <ul className="home-sub-nav">
      <li className={`${props.active === 'data-infuser' ? 'active' : ''}`} onClick={() => props.moveSlide(2)}>
        Data Infuser
      </li>
      <li className={`${props.active === 'data-verifier' ? 'active' : ''}`} onClick={() => props.moveSlide(3)}>
        AI Data Verifier
      </li>
      <li className={`${props.active === 'doc-infuser' ? 'active' : ''}`} onClick={() => props.moveSlide(4)}>
        Doc Infuser
      </li>
      <li className={`${props.active === 'datamap' ? 'active' : ''}`} onClick={() => props.moveSlide(5)}>
        Data Map
      </li>
      <li className={`${props.active === 'qufa-studio' ? 'active' : ''}`} onClick={() => props.moveSlide(6)}>
        QuFa Studio
      </li>
    </ul>
  );
}
