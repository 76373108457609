import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Divider } from '@mui/material';

import { histories } from '../../components/data/Histories';

const BigData: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="history-container">
      <Container maxWidth="lg" fixed className="history-content-box">
        <Grid container className="history-grid-container" spacing={3}>
          {histories.map((item, index) => (
            <Grid item xs={12} key={index}>
              {item.isYearVisible && <Box className="year-box">{item.year}</Box>}
              {item.isYearVisible && <Divider className="patent-divider" />}
              <Grid container className="content-box">
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Box className="month-box">{item.month}</Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <Box className="content-detail-box" dangerouslySetInnerHTML={{ __html: item.content }}></Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default BigData;
