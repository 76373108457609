import { Box, Container, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TopNav from './TopNav';
import { Footer } from './Footer';
import { useState } from 'react';
import ScrollHelper from '../../src/views/home/ScrollHelper';
// import { PropsWithChildren } from 'react';

/**
 * DefaultLayout
 * @param
 * @param
 * @constructor
 */
//
export default function DefaultLayout() {
  const [isNavHide, setIsNavHide] = useState(true);
  return (
    <>
      <TopNav isNavHide={isNavHide}></TopNav>
      <Outlet context={{ isNavHide, setIsNavHide }} />
      <Footer />
    </>
  );
}
