import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem } from '@mui/material';
import bigdataBgImg from '@/assets/imgs/companies/company_backgraound.png';

const OrganizationSummary: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Box className="company-promise-box pb-100">
        <Box className="">
          <Typography className="word-49 white" display="inline">
            프람트테크놀로지
          </Typography>
          <Typography className="word-41 white" display="inline">
            는
          </Typography>
          <Typography className="word-41 white">공공분야 SI전문기업으로서</Typography>
          <Typography className="word-41 white">새로운 도전과 혁신으로 시장을 주도해 나갈 것입니다.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationSummary;
