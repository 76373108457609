import React from 'react';
import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem, Divider } from '@mui/material';
import bigdataBgImg from '@/assets/imgs/home/bigdata_slide_bg.png';
import companyBusinessImage from '@/assets/company/company-business.png';
import companyDescImage from '@/assets/company/company-desc.png';

const OrganizationBusiness: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Box className="product-infuser-character-header-box">
          <Typography className="word-47">주요사업</Typography>
        </Box>
        <Box className="infuser-desc-row">
          <Typography className="infuser-desc-sub bold-word">프람트테크놀로지</Typography>
          <Typography className="infuser-desc-sub">
            는 API 구축,
            <br />
          </Typography>
          <Typography className="infuser-desc-sub">시스템 운영/유지보수 등&nbsp;</Typography>
          <Typography className="infuser-desc-sub bold-word">공공 SI분야의 전문기업</Typography>
          <Typography className="infuser-desc-sub">입니다.</Typography>
        </Box>
        <Box className="infuser-desc-box">
          <img src={companyBusinessImage} className="company-desc-img" />
        </Box>
      </Grid>
    </Box>
  );
};

export default OrganizationBusiness;
