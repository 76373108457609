import { Box, Container, useMediaQuery, useTheme, Grid, Typography, List, ListItem } from '@mui/material';
import infuserGatewayImg from '@/assets/product/infuser/gateway-bg.png';

import ModuleIcon from '@/assets/icon/module.svg';
import KeyIcon from '@/assets/icon/key.svg';
import GraphIcon from '@/assets/icon/graph.svg';
import DocIcon from '@/assets/icon/doc.svg';
import IconBox from 'src/components/iconbox/IconBox';

const DataInfuserComponentGateWay: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="product-section">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Box className="img-bg-box">
            <Box className={`bg-item white word-26 p1-20`}>GATEWAY(게이트웨이)</Box>
            <img src={infuserGatewayImg} alt="Data Infuser Manager" className="data-infuser-manager" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={6}>
          <Box className="word-20 fw-4">
            Gateway는 <strong>사용자의 요청에 따른 API를 호출하고 그 결과를 반환해주는 서비스</strong>입니다.
            <br />
            <br />
            HTTP 프로토콜(REST, SOAP, gRPC, GraphQL 등)을 사용하는 다양한 API를 지원하며 사용자 요청에 포함된 인증키를
            바탕으로 API 사용을 위한 권한을 확인합니다.
            <br />
            <br />
            설정된 허용 횟수에 따라 API 호출 건수를 제어할 수 있으며 허용 횟수는 다양한 간격(초, 분, 시, 일, 월 등)별로
            설정할 수 있습니다.
            <br />
            <br />
            사용자의 요청을 Caching하여 보다 빠른 응답속도를 보장하고 Gateway에 연계된 API 서비스들의 주기적인
            헬스체크를 통하여 안정적인 서비스를 제공합니다.
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Box className={`bg-item ${isMobile ? 'block-sm' : ''}`}>
            <Grid container spacing={1}>
              <IconBox
                icon={ModuleIcon}
                text={
                  <>
                    API <br /> 연계모듈
                  </>
                }
              />
              <IconBox
                icon={KeyIcon}
                text={
                  <>
                    인증모듈
                    <br />
                  </>
                }
              />
              <IconBox
                icon={GraphIcon}
                text={
                  <>
                    트래픽 <br /> 제어 모듈
                  </>
                }
              />
              <IconBox
                icon={DocIcon}
                text={
                  <>
                    Caching <br /> 모듈
                  </>
                }
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DataInfuserComponentGateWay;
