import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';

import styles from './carousel.module.css';
import useIsMobile from '../mobile/useInMobile';

export interface SlickCarouselProps {
  elements?: JSX.Element[];
  settings?: SlickProps;
}

export interface SlickProps {
  arrows?: boolean;
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  centerMode?: boolean;
  centerPadding?: string; // 10px
}

export default function SlickCarousel(props: SlickCarouselProps) {
  const windowSize = useIsMobile() ? 400 : 600;
  const defaultSettings = {
    className: 'slider variable-width',
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <Box component="div" className={`${styles['slider-container']}`}>
      <Slider {...defaultSettings}>
        {props?.elements?.map((el, index) => (
          <div style={{ width: windowSize }} key={index}>
            {el}
          </div>
        ))}
      </Slider>
    </Box>
  );
}
