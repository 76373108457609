import { CarouselSettings, ImageCarousel } from '@/components/carousel/ImageCarousel';

import bannerBgImg from '@/assets/banner/di_bg.png';
import qufaImg from '@/assets/qufa_slide_v2.png';

import { Box, Container, Grid, Typography } from '@mui/material';
import InfuserBanner from '../home/InfuserBanner';
import { FullPage, Slide } from 'react-full-page';
import { useOutletContext } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { Footer } from '@/layouts/Footer';

export function TemplateIndex() {
  const carouselItems: JSX.Element[] = [InfuserBanner(), <Box component={'img'} src={qufaImg}></Box>];
  const { isNavHide, setIsNavHide } = useOutletContext<{
    isNavHide: boolean;
    setIsNavHide: Dispatch<SetStateAction<boolean>>;
  }>();

  const carouselSettings: CarouselSettings = {
    autoPlay: true,
    showArrows: true,
    swipeable: true,
  };

  return (
    <FullPage
      controls={false}
      duration={1200}
      afterChange={(param: { from: number; to: number }) => {
        if (!setIsNavHide) {
          return;
        }

        if (
          (isNavHide === true && param.from === 0 && param.to === 0) ||
          (isNavHide === false && param.from === 1 && param.to === 0)
        ) {
          setIsNavHide(true);
        } else {
          setIsNavHide(false);
        }
      }}
    >
      <Slide>
        <Box
          sx={{
            backgroundImage: `url(${bannerBgImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              height: '100%',
              paddingTop: '64px',
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <ImageCarousel elements={carouselItems} settings={carouselSettings}></ImageCarousel>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Slide>
      <Slide>
        <Box sx={{ height: '100%', backgroundColor: '#444' }}>
          <Typography>Another slide content</Typography>
        </Box>
      </Slide>
      <Slide>
        <Box sx={{ height: '100%', backgroundColor: '#F00' }}>
          <Typography>Another slide content</Typography>
        </Box>
      </Slide>
      <Slide>
        <Box sx={{ height: '100%', backgroundColor: '#444' }}>
          <Typography>Another slide content</Typography>
        </Box>
      </Slide>
      <Slide>
        <Box sx={{ height: '100%', backgroundColor: '#444' }}>
          <Typography>Another slide content</Typography>
        </Box>
      </Slide>
      <Slide style={{ maxHeight: '200px' }}>
        <Footer></Footer>
      </Slide>
    </FullPage>
  );
}
