import TopBanner from '@/components/product/TopBanner';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import BannerImage from '@/assets/imgs/products/qufa_banner.png';
import BannerTitleImage from '@/assets/imgs/products/qufa_banner_title.png';
import QufaStudioMain from './qufa_studio/QufaStudioMain';
import QufaStudioDifferentiation from './qufa_studio/QufaStudioDifferentiation';

export default function QufaStudio() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <TopBanner bgImg={BannerImage} typeImg={BannerTitleImage} title="Qufa Studio" desc="쿠파 스튜디오" />
      <Container maxWidth="xl">
        <QufaStudioMain />
        <QufaStudioDifferentiation />
      </Container>
    </Box>
  );
}
